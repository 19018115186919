define('ui/components/form-ports/component', ['exports', 'ember', 'ui/utils/parse-port'], function (exports, _ember, _uiUtilsParsePort) {

  var protocolOptions = [{ label: 'TCP', value: 'tcp' }, { label: 'UDP', value: 'udp' }];

  exports['default'] = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),

    // The initial ports to show, as an array of objects
    initialPorts: null,

    // Ignore the ID and force each initial port to be considered 'new' (for clone)
    editing: false,
    tagName: '',
    portsArray: null,
    portsAsStrArray: null,
    protocolOptions: protocolOptions,
    showIp: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var out = [];
      var ports = this.get('initialPorts');
      if (ports) {
        ports.forEach(function (value) {
          if (typeof value === 'object') {
            var pub = '';
            var existing = !!value.id;
            if (value.publicPort) {
              pub = value.publicPort + '';
            }

            if (value.bindAddress) {
              _ember['default'].run.next(function () {
                _this.send('showIp');
              });
            }

            out.push({
              existing: existing,
              obj: value,
              bindAddress: value.bindAddress || null,
              'public': pub,
              'private': value.privatePort,
              protocol: value.protocol
            });
          } else if (typeof value === 'string') {
            // Strings, from clone/edit
            var parsed = (0, _uiUtilsParsePort.parsePortSpec)(value);

            if (parsed.hostIp) {
              _ember['default'].run.next(function () {
                _this.send('showIp');
              });
            }

            out.push({
              existing: false,
              bindAddress: parsed.hostIp,
              'public': parsed.hostPort,
              'private': parsed.container,
              protocol: parsed.protocol
            });
          } else {
            console.error('Unknown port value', value);
          }
        });
      }

      _ember['default'].run.scheduleOnce('afterRender', function () {
        _this.set('portsArray', out);
        _this.portsArrayDidChange();
      });
    },

    actions: {
      addPort: function addPort() {
        this.get('portsArray').pushObject({ 'public': '', 'private': '', protocol: 'tcp' });
      },

      removePort: function removePort(obj) {
        this.get('portsArray').removeObject(obj);
      },

      showIp: function showIp() {
        this.set('showIp', true);
      }
    },

    portsArrayDidChange: (function () {
      var out = [];
      this.get('portsArray').forEach(function (row) {
        if (!row.protocol) {
          return;
        }

        var bindAddress = row.bindAddress;
        if (bindAddress && bindAddress.indexOf(':') > 0 && bindAddress.indexOf('[') !== 0) {
          // IPv6
          bindAddress = '[' + bindAddress + ']';
        }

        // If there's a public and no private, the private should be the same as public.
        if (row['public'] && !row['private']) {
          var str = row['public'] + ':' + row['public'] + '/' + row.protocol;
          if (bindAddress) {
            str = bindAddress + ':' + str;
          }
          out.push(str);
        } else if (row['private']) {
          var str = '';

          if (row['public']) {
            if (bindAddress) {
              str += bindAddress + ':';
            }

            str += row['public'] + ':';
          }

          str += row['private'] + '/' + row.protocol;
          out.push(str);
        }
      });

      this.set('portsAsStrArray', out);
      this.sendAction('changed', this.get('portsArray'));
      this.sendAction('changedStr', this.get('portsAsStrArray'));
    }).observes('portsArray.@each.{public,private,protocol}'),

    validate: (function () {
      var _this2 = this;

      var errors = [];
      var seen = {};

      this.get('portsArray').forEach(function (row) {
        if (!row['private'] && (row['public'] || row.bindAddress)) {
          errors.push(_this2.get('intl').t('formPorts.error.privateRequired'));
        }

        if (row.bindAddress && !row['public']) {
          errors.push(_this2.get('intl').t('formPorts.error.publicRequired'));
        }

        if (row['public']) {
          var key = '[' + (row.bindAddress || '0.0.0.0') + ']:' + row['public'] + '/' + row.protocol;
          if (seen[key]) {
            errors.push(_this2.get('intl').t('formPorts.error.' + (row.bindAddress ? 'mixedIpPort' : 'mixedPort'), {
              ip: row.bindAddress,
              port: row['public'],
              proto: row.protocol
            }));
          } else {
            seen[key] = true;
          }
        }
      });

      this.set('errors', errors.uniq());
    }).observes('portsArray.@each.{bindAddress,public,private,protocol}')
  });
});
define('ui/admin-tab/auth/activedirectory/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    resourceType: 'ldapconfig',

    model: function model() {
      var _this = this;

      return this.get('userStore').find(this.get('resourceType'), null, { forceReload: true }).then(function (collection) {
        var existing = collection.get('firstObject');

        // On install the initial ldapconfig is empty.  For any fields that are empty, fill in the default from the schema.
        var defaults = _this.get('userStore').getById('schema', _this.get('resourceType')).get('resourceFields');
        Object.keys(defaults).forEach(function (key) {
          var field = defaults[key];
          if (field && field['default'] && !existing.get(key)) {
            existing.set(key, field['default']);
          }
        });

        return existing;
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        confirmDisable: false,
        testing: false,
        organizations: this.get('session.orgs') || [],
        errors: null
      });
    }
  });
});
define('ui/admin-tab/auth/activedirectory/controller', ['exports', 'ember', 'ui/utils/errors', 'ui/utils/constants'], function (exports, _ember, _uiUtilsErrors, _uiUtilsConstants) {

  var PLAIN_PORT = 389;
  var TLS_PORT = 636;

  exports['default'] = _ember['default'].Controller.extend({
    access: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    confirmDisable: false,
    errors: null,
    testing: false,

    providerName: 'ldap.providerName.ad',
    userType: _uiUtilsConstants['default'].PROJECT.TYPE_LDAP_USER,
    groupType: _uiUtilsConstants['default'].PROJECT.TYPE_LDAP_GROUP,

    addUserInput: '',
    addOrgInput: '',

    username: '',
    password: '',

    createDisabled: (function () {
      return !this.get('username.length') || !this.get('password.length');
    }).property('username.length', 'password.length'),

    numUsers: (function () {
      return (this.get('model.allowedIdentities') || []).filterBy('externalIdType', this.get('userType')).get('length');
    }).property('model.allowedIdentities.@each.externalIdType', 'userType', 'groupType'),

    numGroups: (function () {
      return (this.get('model.allowedIdentities') || []).filterBy('externalIdType', this.get('groupType')).get('length');
    }).property('model.allowedIdentities.@each.externalIdType', 'userType', 'groupType'),

    tlsChanged: (function () {
      var on = this.get('model.tls');
      var port = parseInt(this.get('model.port'), 10);

      if (on && port === PLAIN_PORT) {
        this.set('model.port', TLS_PORT);
      } else if (!on && port === TLS_PORT) {
        this.set('model.port', PLAIN_PORT);
      }
    }).observes('model.tls'),

    actions: {
      test: function test() {
        var _this = this;

        this.send('clearError');

        var model = this.get('model');
        model.setProperties({
          enabled: false,
          'accessMode': 'unrestricted'
        });

        var errors = model.validationErrors();
        if (errors.get('length')) {
          this.set('errors', errors);
        } else {
          this.set('testing', true);
          model.save().then(function () {
            _this.send('authenticate');
          })['catch'](function (err) {
            _this.send('gotError', err);
          });
        }
      },

      authenticate: function authenticate() {
        var _this2 = this;

        this.send('clearError');
        var code = this.get('username') + ':' + this.get('password');
        this.get('access').login(code).then(function (res) {
          _this2.send('authenticationSucceeded', res.body);
        })['catch'](function (err) {
          _this2.send('gotError', err);
        });
      },

      authenticationSucceeded: function authenticationSucceeded(auth) {
        var _this3 = this;

        this.send('clearError');
        this.set('organizations', auth.orgs);

        // Set this to true so the token will be sent with the request
        this.set('access.enabled', true);

        var model = this.get('model');
        model.setProperties({
          'enabled': true,
          'accessMode': 'unrestricted',
          'allowedIdentities': [auth.userIdentity]
        });

        model.save().then(function () {
          _this3.send('waitAndRefresh');
        })['catch'](function (err) {
          _this3.set('access.enabled', false);
          _this3.send('gotError', err);
        });
      },

      waitAndRefresh: function waitAndRefresh(url) {
        $('#loading-underlay, #loading-overlay').removeClass('hide').show();
        setTimeout(function () {
          window.location.href = url || window.location.href;
        }, 1000);
      },

      promptDisable: function promptDisable() {
        this.set('confirmDisable', true);
        _ember['default'].run.later(this, function () {
          this.set('confirmDisable', false);
        }, 10000);
      },

      gotError: function gotError(err) {
        this.set('errors', [_uiUtilsErrors['default'].stringify(err)]);
        this.set('testing', false);
      },

      clearError: function clearError() {
        this.set('errors', null);
      },

      disable: function disable() {
        var _this4 = this;

        this.send('clearError');

        var model = this.get('model');
        model.setProperties({
          enabled: false
        });

        model.save().then(function () {
          _this4.get('access').clearSessionKeys();
          _this4.set('access.enabled', false);
          _this4.send('waitAndRefresh');
        })['catch'](function (err) {
          _this4.send('gotError', err);
        })['finally'](function () {
          _this4.set('confirmDisable', false);
        });
      }
    }
  });
});